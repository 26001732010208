let blobUrls = {}

export const setBlob = (remoteSrc: string, blobUrl: string): void => {
  blobUrls[remoteSrc] = blobUrl
}

export const getBlob = (remoteSrc: string): string => {
  return blobUrls[remoteSrc]
}

export const removeBlob = (remoteSrc: string): void => {
  delete blobUrls[remoteSrc]
}

export const removeAllBlobs = (): void => {
  blobUrls = {}
}
