import { AxiosResponse } from 'axios'

import { IUser } from 'atw-shared/utils'
import { COUNTRY_FLAG_SIZE } from 'client/utils'

export const getCountryFlagUrl = (user: IUser) => {
  if (!user) return null
  return `https://flagsapi.com/${user.country}/shiny/${COUNTRY_FLAG_SIZE}.png`
}

export const getUserAvatarUrl = (bucketUrl: string, userId: string): string => {
  if (!userId) {
    return ''
  }
  return `${bucketUrl}/users/${userId}/avatar.png`
}

export const getAssetUrl = (filename: string): string => {
  return `https://atw-fe-assets.s3.eu-central-1.amazonaws.com/${filename}`
}

export const imgResToBlobUrl = (res: AxiosResponse): string => {
  const blob = new Blob([Buffer.from(res.data, 'binary')], {
    type: 'image/png',
  })
  return URL.createObjectURL(blob)
}

export const getQuestionUrl = (questionId: string) => {
  return `/question?_id=${questionId}`
}
