import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'

import { Lang } from 'atw-shared/utils'
import { Theme } from 'react-toastify'

const ROOT_KEY = 'nums-dont-lie-app'

const getRootConfig = (): string | null => {
  let value
  try {
    value = window.localStorage.getItem(ROOT_KEY)
  } catch {
    value = null
  }
  return value
}

const getLocalValue = (path: string): object | null => {
  const rootConfig = getRootConfig()
  const currentValue = JSON.parse(rootConfig) || {}
  return get(currentValue, path, null)
}

const setLocalValue = (path: string, value: string): void => {
  const rootConfig = getRootConfig()
  const currentValue = JSON.parse(rootConfig) || {}
  window.localStorage.setItem(ROOT_KEY, JSON.stringify(set(currentValue, path, value)))
}

const removeLocalItem = (path: string): void => {
  const rootConfig = getRootConfig()
  const currentValue = JSON.parse(rootConfig) || {}
  unset(currentValue, path)
  window.localStorage.setItem(ROOT_KEY, JSON.stringify(currentValue))
}

export const setLocalAuthUser = ({ id, token }: { id: string; token: string }): void => {
  setLocalValue('authUser.id', id)
  setLocalValue('authUser.token', token)
}

export const setLocalUserConfig = (id: string, lang?: Lang, theme?: Theme): void => {
  if (lang) {
    setLocalValue(`users.${id}.lang`, lang)
  }
  if (theme) {
    setLocalValue(`users.${id}.theme`, theme)
  }
}

export const removeLocalAuthUser = (): void => {
  removeLocalItem('authUser.token')
}

export const getLocalAuthUser = (): { id?: string; token?: string } => {
  return getLocalValue('authUser') || {}
}

export const getLocalUserConfig = (id: string): { lang?: Lang; theme?: Theme } => {
  return getLocalValue(`users.${id}`) || {}
}

export const getLocalLastUserConfig = (): { lang?: Lang; theme?: Theme } => {
  const user = getLocalAuthUser()
  return user?.id ? getLocalUserConfig(user.id) : {}
}
