import { io, Socket } from 'socket.io-client'
import { QueryClient } from '@tanstack/react-query'

import { ApiUrlPath, IQuestion, SocketEvent } from 'atw-shared/utils'
import { removeLocalAuthUser } from 'client/services'

let recoverEmailSocket: Socket
let userSocket: Socket
let questionSocket: Socket

const getUpdatedQuestion = (
  currentData: { count: number; data: IQuestion[] },
  all,
  submittedTimes: number
) => {
  return {
    ...currentData,
    data: [
      {
        ...currentData.data[0],
        submittedTimes,
        answeredStats: {
          ...currentData.data[0].answeredStats,
          all,
        },
      } as IQuestion,
    ],
  }
}

const resetQuestionEventListeners = (queryClient: QueryClient, questionId: string) => {
  questionSocket.removeAllListeners()

  questionSocket.on('connect', () => {
    questionSocket.emit('room', 'question:' + questionId)
  })

  questionSocket.on('answer', ({ selectedIndexes }: { selectedIndexes: number[] }) => {
    queryClient.setQueryData(
      [ApiUrlPath.GetQuestion, questionId],
      (currentData: { count: number; data: IQuestion[] }) => {
        const question = currentData.data[0]
        const answersCount = (question.submittedTimes += selectedIndexes.length)
        const all = { ...question.answeredStats.all }
        selectedIndexes.forEach((i: number) => {
          all[i] += 1
        })
        return getUpdatedQuestion(currentData, all, answersCount)
      }
    )
  })

  questionSocket.on(
    'reanswer',
    ({
      oldSelectedIndexes,
      newSelectedIndexes,
    }: {
      oldSelectedIndexes: number[]
      newSelectedIndexes: number[]
    }) => {
      queryClient.setQueryData(
        [ApiUrlPath.GetQuestion, questionId],
        (currentData: { count: number; data: IQuestion[] }) => {
          const question = currentData.data[0]
          let answersCount = question.submittedTimes
          const all = { ...question.answeredStats.all }
          question.options.forEach((answer: string, i: number) => {
            if (oldSelectedIndexes.includes(i) && !newSelectedIndexes.includes(i)) {
              all[i] -= 1
              answersCount -= 1
            } else if (
              !oldSelectedIndexes.includes(i) &&
              newSelectedIndexes.includes(i)
            ) {
              all[i] += 1
              answersCount += 1
            }
          })
          return getUpdatedQuestion(currentData, all, answersCount)
        }
      )
    }
  )
}

export const startRecoverEmailSocket = async (
  email: string,
  setRecoverPassToken: (token: string) => void
) => {
  recoverEmailSocket = io(window.location.origin, {
    path: process.env.API_PATH + '/socket.io/email',
  })

  recoverEmailSocket.on('connect', () => {
    recoverEmailSocket.emit('room', 'email:' + email)
  })

  recoverEmailSocket.on(SocketEvent.EnablePasswordRecoveryField, data => {
    setRecoverPassToken(data.token)
  })
}

export const startUserSocket = async (userId: string) => {
  userSocket = io(window.location.origin, {
    path: process.env.API_PATH + '/socket.io/users',
  })

  userSocket.on('connect', () => {
    userSocket.emit('room', 'user:' + userId)
  })

  userSocket.on(SocketEvent.AppReload, () => {
    window.location.reload()
  })

  userSocket.on(SocketEvent.Logout, () => {
    removeLocalAuthUser()
    window.location.reload()
  })

  userSocket.on(SocketEvent.Logout, () => {
    removeLocalAuthUser()
    window.location.reload()
  })
}

export const startQuestionSocket = async (
  queryClient: QueryClient,
  questionId: string
) => {
  if (!questionSocket) {
    questionSocket = io(window.location.origin, {
      path: process.env.API_PATH + '/socket.io/questions',
    })
    resetQuestionEventListeners(queryClient, questionId)
  } else {
    resetQuestionEventListeners(queryClient, questionId)
    questionSocket.emit('room', 'question:' + questionId)
  }
}

export const stopQuestionSocket = () => {
  questionSocket?.removeAllListeners()
}

export const killRecoverEmailSocket = () => {
  recoverEmailSocket?.disconnect()
}

export const killUserSocket = () => {
  userSocket?.disconnect()
}

export const killQuestionSocket = () => {
  questionSocket?.disconnect()
}
