import { AccountStatus } from 'atw-shared/utils'

export const isAccountConfirmed = (accountStatus: AccountStatus) => {
  const allowed = [AccountStatus.CONFIRMED, AccountStatus.FREE, AccountStatus.PAID]
  return allowed.includes(accountStatus)
}

export const isAccountFull = (accountStatus: AccountStatus) => {
  const allowed = [AccountStatus.FREE, AccountStatus.PAID]
  return allowed.includes(accountStatus)
}
