import times from 'lodash/times'

import { IQuestion, MAX_NUM_OF_QUESTION_OPTIONS, QuestionType } from 'atw-shared/utils'

import { CreateQuestionFormValues, QuestionSubmitFormValues } from 'client/utils'

export const getCreateQuestionInitialValues = (
  type = QuestionType.SingleChoice,
  categories = [],
  text = '',
  canBeReanswered = true
): CreateQuestionFormValues => {
  let numOfOptions = -1
  let numOfSelectableOptions = -1
  let rangeOfSelectableOptions = [-1, -1]

  switch (type) {
    case QuestionType.SingleChoice:
    case QuestionType.SingleChoiceYesOrNo:
      numOfOptions = 2
      numOfSelectableOptions = 1
      break
    case QuestionType.MultiChoiceExact:
      numOfOptions = 10
      numOfSelectableOptions = 5
      break
    case QuestionType.MultiChoiceRange:
      numOfOptions = 5
      rangeOfSelectableOptions = [1, 5]
      break
    case QuestionType.Rating:
      numOfOptions = 5
      numOfSelectableOptions = 1
      break
  }

  const values = {
    type,
    categories,
    text,
    canBeReanswered,
    numOfOptions,
    numOfSelectableOptions,
    rangeOfSelectableOptions,
    options: {},
  } as CreateQuestionFormValues

  const fillWithNumbers = type === QuestionType.Rating

  times(MAX_NUM_OF_QUESTION_OPTIONS, i => {
    values.options[i + 1] =
      fillWithNumbers && i < values.numOfOptions ? String(i + 1) : ''
  })

  if (type === QuestionType.SingleChoiceYesOrNo) {
    values.options[1] = 'YES'
    values.options[2] = 'NO'
  }

  return values
}

export const getSubmitQuestionInitialValues = (
  question?: IQuestion
): QuestionSubmitFormValues => {
  const values = {
    votes: {},
  }

  times(question?.options.length, i => {
    values.votes[i] = false
  })

  // when reanswering
  if (question?.answeredStats) {
    times(question.answeredStats.byRequestor.length, i => {
      values.votes[question.answeredStats.byRequestor[i]] = true
    })
  }

  return values
}
