import { displayRequestTimeoutError, getLocalLastUserConfig } from 'client/services'
import { DEFAULT_LANG, MIN_REQ_TIME_MS } from 'client/utils'

const API_PATH = process.env.API_PATH

export const getAbortController = (
  timeout: number // in seconds
): {
  signal: AbortSignal
  clear: () => void
} => {
  const controller = new AbortController()

  controller.signal.addEventListener('abort', () => {
    displayRequestTimeoutError()
  })

  const timeoutId = setTimeout(() => {
    controller.abort()
    clearTimeout(timeoutId)
  }, timeout * 1000)

  return {
    signal: controller.signal,
    clear: () => {
      clearTimeout(timeoutId)
    },
  }
}

export const fetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  minReqTimeMs = MIN_REQ_TIME_MS
): Promise<Response> => {
  const timestamp = Date.now()

  const res = await global.fetch(API_PATH + input, {
    ...init,
    headers: {
      ...init.headers,
      language: getLocalLastUserConfig()?.lang || DEFAULT_LANG,
    },
  })

  if (
    process.env.NODE_ENV !== 'test' &&
    minReqTimeMs > 0 &&
    Date.now() - timestamp < minReqTimeMs
  ) {
    await new Promise(resolve =>
      setTimeout(resolve, minReqTimeMs - (Date.now() - timestamp))
    )
  }

  return res
}
