import { formatDistanceToNow, formatDuration, intervalToDuration, format } from 'date-fns'
import { pl, enUS } from 'date-fns/locale'

import { DATE_TIME_FORMAT } from 'client/utils'

const locales = { pl, en: enUS }

export const humanizeFromToNow = (timestamp: number, appLang: string): string => {
  if (!timestamp) {
    return null
  }

  return formatDistanceToNow(timestamp, {
    addSuffix: true,
    locale: locales[appLang.toLowerCase()],
  })
}

export const humanizeDuration = (
  date: number,
  baseDate: number,
  appLang: string
): string => {
  if (!date || !baseDate) {
    return null
  }

  return formatDuration(
    intervalToDuration({
      start: baseDate,
      end: date,
    }),
    {
      locale: locales[appLang.toLowerCase()],
    }
  )
}

export const formatTimestamp = (timestamp: number, appLang: string) => {
  if (!timestamp) {
    return null
  }

  return format(timestamp, DATE_TIME_FORMAT, {
    locale: locales[appLang.toLowerCase()],
  })
}
