import { toast } from 'react-toastify'

import { AppMsg } from 'atw-shared/utils'
import i18n from 'client/dictionary/i18n'

const { t } = i18n

export const displaySnackBar = ({ code, type, text }: AppMsg) => {
  if (code) {
    toast[type](t(`apiMsgs.${code}`))
  } else {
    toast[type](text)
  }
}

export const displayRequestTimeoutError = () => {
  toast.error(t('common.errors.requestTimeout'))
}

export const displaySomethingWentWrongError = () => {
  toast.error(t('common.errors.somethingWentWrong'))
}
