import keys from 'lodash/keys'
import values from 'lodash/values'

import { IUser, AccountStatus, QuestionType } from 'atw-shared/utils'
import { ApiErrors, CreateQuestionFormValues } from 'client/utils'

export const getAccountStatus = (user: IUser): AccountStatus => {
  let accountStatus = AccountStatus.UNKNOWN

  if (user) {
    accountStatus = AccountStatus.CREATED
    if (user.config.confirmed) {
      accountStatus = AccountStatus.CONFIRMED
      if (user.config.payment) {
        accountStatus = AccountStatus.PAID
        if (user.config.payment.type === AccountStatus.FREE) {
          accountStatus = AccountStatus.FREE
        }
      }
    }
  }

  return accountStatus
}

export const parseApiErrors = (errors: ApiErrors): Record<string, string> => {
  const parsed = {}
  keys(errors).forEach(
    key => (parsed[String(key)] = errors[key].properties?.message || errors[key].message)
  )
  return parsed
}

export const createQuestionFormValuesToBody = (formValues: CreateQuestionFormValues) => {
  const {
    type,
    categories,
    text,
    options,
    numOfSelectableOptions,
    rangeOfSelectableOptions,
    canBeReanswered,
  } = formValues

  const [min, max] = rangeOfSelectableOptions

  return {
    type,
    categories,
    text,
    options: values(options).filter(o => o),
    selectableOptions:
      type === QuestionType.MultiChoiceRange
        ? { range: { min, max } }
        : { exact: numOfSelectableOptions },
    canBeReanswered,
  }
}
